<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body class="mb-0">
          <b-table
            :striped="true"
            :bordered="true"
            :hover="true"
            ref="refUserListTable"
            class="position-relative"
            :items="orderDetail"
            responsive
            :fields="tableColumns"
            primary-key="id"
            sort-by.sync="id"
            show-empty
            empty-text="Kayıt bulunamadı."
            head-variant="dark"
          >
            <template #cell(unit)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.unitFormatted }} {{ data.item.unitType }}
              </div>
            </template>
            <template #cell(unitPrice)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.unitPriceFormatted }} {{ data.item.priceUnit }}
              </div>
            </template>
            <template #cell(totalPrice)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.totalPriceFormatted }} {{ data.item.priceUnit }}
              </div>
            </template>
            <template #cell(netTotalPrice)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.netTotalPriceFormatted }} {{ data.item.priceUnit }}
              </div>
            </template>
            <template #cell(date)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ format(new Date(data.item.date)) }}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <download-excel
                  :data="orderDetail"
                  type="xls"
                  name="SiparişDetayListesi.xls"
                  :fields="json_fields"
                  worksheet="SiparişDetayListesi"
                >
                  <b-button
                    style="margin-top: 0px"
                    type="button"
                    variant="primary"
                  >
                    <feather-icon icon="DownloadCloudIcon" class="mr-50" />
                    <span class="align-middle">Excel Olarak İndir</span>
                  </b-button>
                </download-excel>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <div>
                  <h6 class="mb-2">
                    <strong> Sipariş Toplamı</strong>
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <strong> Dip Toplam:</strong>
                        </td>
                        <td>
                          {{ this.orderTotal[0].deepTotalFormatted }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> Net Toplam:</strong>
                        </td>
                        <td>{{ this.orderTotal[0].netTotalFormatted }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> İskonto Tutarı:</strong>
                        </td>
                        <td>{{ this.orderTotal[0].discountTotalFormatted }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <strong> KDV'li Toplam:</strong>
                        </td>
                        <td>
                          {{ this.orderTotal[0].grandTotalPriceFormatted }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BTable,
  BTableLite,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BModal,
    BOverlay,
    BFormInput,
    BTableLite,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  },
  data() {
    return {
      orderDetail: [],
      orderTotal: [],
      tableColumns: [
        { key: "code", label: "Stok Kodu" },
        { key: "unit", label: "Miktar" },
        { key: "unitPrice", label: "Birim Fiyat" },
        { key: "totalPrice", label: "İndirimli Toplam Fiyat" },
        { key: "netTotalPrice", label: "Net Toplam Fiyat" },
        { key: "date", label: "Tarih" }
      ],
      ordersCount: 0,
      currentPage: 1,
      show: false,
         json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      json_fields: {
        StokKodu: "code",
        Miktar: "unit",        
        "Birim": "unitType",
        "Birim Fiyat": "unitPrice",
        "Kur": "priceUnit",
        "İndirimli Toplam Fiyat": "totalPrice",
        "Net Toplam Fiyat": "netTotalPrice",
        Tarih: {
          field: "date",
          callback: value => {
            return this.format(new Date(value));
          }
        }
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get(`/User/GetERPOrderDetail?OrderId=${router.currentRoute.params.id}`)
        .then(response => {
          this.orderDetail = response.data.result;
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });

      this.$http
        .get(
          `/User/GetERPOrderDetailTotal?OrderId=${router.currentRoute.params.id}`
        )
        .then(response => {
          this.orderTotal = response.data.result;
          this.show = false;
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
